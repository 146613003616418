import React from 'react';
import client from '../../api/client';
import Spinner from '../Global/Spinner';
import STYLES from '../../css/styles';
export default function ExportCSV() {
	const [loading, setLoading] = React.useState(false);
	const exportCsv = async () => {
		setLoading(true);
		try {
			const { data } = await client.get('/song/export/csv');
			const { csv } = data;
			const element = document.createElement('a');
			const file = new Blob([csv], { type: 'text/csv' });
			element.href = URL.createObjectURL(file);
			element.download = 'songs.csv';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		} catch (error) {
			console.error('Error exporting CSV:', error);
		}
		setLoading(false);
	};

	if (loading) return <Spinner inline={true} />;
	return (
		<div>
			<button
				className={STYLES.BUTTON_BLUE}
				onClick={exportCsv}
				disabled={loading}
			>
				Export All Songs to CSV
			</button>
		</div>
	);
}
